ul {
  border: 0;
  font: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

a,
a:visited,
a:hover,
a:active {
  text-decoration: none !important;
}

.thumbnail {
  vertical-align: top;
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

*:focus {
  outline: none;
}

ins.adsbygoogle[data-ad-status="unfilled"] {
  display: none !important;
}

.nsfw {
  color: #c50000;
  font-weight: 900 !important;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.image-blur {
  vertical-align: top;
  max-width: 100%;
  text-align: center;
  filter: blur(8px);
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
